p {
    font-size: 24px;
}

#signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

#signup form {
    border: 0.5px solid #aee8ff;
    padding: 26px;
    min-width: 30%;
}

#signup input[type=text] {
    padding: 10px 12px;
    min-width: 40%;
    background-color: transparent;
    border: 1px solid #aee8ff;
    outline: none;
    color: #aee8ff;
    text-align: left;
}

#signup input[type=password] {
    padding: 10px 12px;
    min-width: 40%;
    background-color: transparent;
    border: 1px solid #aee8ff;
    outline: none;
    color: #aee8ff;
    text-align: left;
}

#signup input[type=submit] {
    padding: 10px;
    width: 80%;
    background-color: #aee8ff;
    border: 1px solid #1e1e1e;
    border-radius: 6px;
    cursor: pointer;
}

.login-google {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    background-color: #aee8ff;
    border: 1px solid #1e1e1e;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    
    margin-left: auto;
    margin-right: auto;
}