@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body {
    font-family: 'Lato', sans-serif;
    background-color: rgb(30, 30, 30); /* #1e1e1e */
    color: white;
}

.App {
    padding: 0px;
}

.App-header {
    box-sizing: border-box;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 50vh;
}

.headerText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 100%;
    padding: 0px 20px;
    box-sizing: border-box;

}

.headerTitle {
    font-size: 2.5em;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
    text-align: left;
    margin-top: 20px;
}

.headerDesc {
    font-size: 1.5em;
    font-weight: 300;
    margin: 0px;
    padding: 0px;
    text-align: left;
    margin-top: 10px;
}

.headerBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 18px;
    border-radius: 6px;
}

.headerBoxText {
    font-size: 1.3em;
    text-align: center;
    margin-top: 10px;
    color: #1e1e1e;
}

.headerBoxInput {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    margin: 10px 0 18px 0;
    width: 90%
}

.headerBoxTextBox {
    width: 80%;
    border-radius: 4px;
    border: 1px solid #1e1e1e;
    background-color: transparent;
    height: 25px;
    padding: 4px 0 4px 12px;

    cursor: text;
    outline: none;
    text-align: left;
}

.headerBoxButton {
    background-color: #1e1e1e;
    border: none;
    border-radius: 4px;
    padding: 4px 12px;
    margin-left: 10px;

    display: flex;
}

.main-img {
    height: 200px;
    width: 200px;
}

@media screen and (max-width: 900px) {
    .header {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80vh;
    }

    .headerText {
        width: 100%;
        max-width: 90%;
    }

    .headerTitle, .headerDesc {
        text-align: center;
    }

    .headerBox {
        padding: 18px;
        border-radius: 6px;
        margin: 0px 26px 26px 26px;
    }

    .headerBoxInput {
        width: 100%;
    }

}

/* Columns */

.about {
    background-color: white;
    color: #1e1e1e;
    padding: 20px 0px 50px 0;
}

.about-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #1e1e1e;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 40px;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    max-width: 30%;
    text-align: center;

    margin: 0px 20px;
    padding: 0px 20px;
    box-sizing: border-box;

    border-radius: 12px;
}

.column h1 {
    font-size: 1.5em;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
    text-align: center;
    margin-top: 20px;
}

.column p {
    font-size: 1em;
    font-weight: 300;
    text-align: center;
    margin-top: 10px;
}

@media screen and (max-width: 900px) {
    .about {
        padding: 40px 0px 20px 0;
    }
    .about h1 {
        text-align: center;
        font-size: 2em;
        margin: 0px 20px;
    }
    .row {
        flex-direction: column;
        align-items: center;
    }

    .column {
        max-width: 90%;
        margin: 20px 0px;
    }
}

/* Customize */

.customize {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.customize .row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.customize .column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin: 0px 20px;
    border: 1px solid #1e1e1e;
}

.customize .column:hover {
    border: 1px solid #1e1e1e;
    background-color: #1e1e1e;
    color: white;
}

.customize .column h1 {
    font-size: 1.5em;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
    text-align: center;
    margin: 20px;
}

@media screen and (max-width: 900px) {
    .customize .row {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .customize .column {
        width: 90%;
        margin: 20px 0px;
    }
}

/* Footer */

.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #1e1e1e;
    color: white;
    padding: 20px 0px;
}

.footerButton {
    background-color: white;
    border: none;
    border-radius: 4px;
    padding: 4px 12px;
    margin-left: 10px;
    color: #1e1e1e;
    cursor: pointer;
    text-decoration: none;
}

.footerLinks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.footerLinks a {
    color: white;
    text-decoration: none;
    margin: 0px 10px;
}