#portal .popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
  }
  
.popup .popup-item {
    z-index: 1;
    max-width: 600px;
    padding: 18px;
    border-radius: 5px;
    position: relative;
    animation: animate 0.3s;
    margin: 0 14px;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    align-items: start;
}
  
.feed-item-header {
    font-size: 28px;
}

.feed-item-desc {
    font-size: 18px;
    margin-top: 8px;
}

.feed-footer {
    margin-top: 12px;
}

.feed-item-date {
}

.feed-footer-bullet {
    margin: 0px 6px;
}

.feed-item-tags {

}
  
  @keyframes animate {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }