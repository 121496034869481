body {
    padding: 0;
    margin: 0;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid white;
}
  
.nav-list {
    list-style: none; 
    display: flex; 
    justify-content: right; 
    margin: 20px;
}

.nav-list-main {
    list-style: none; 
    display: flex; 
    justify-content: left; 
    padding: 0;
    margin: 20px;
}

.nav-item {
    margin: 0 20px;
}
  
.link {
    text-decoration: none; 
    color: black;
    font-weight: bold;
    font-size: 24px; 

    background-color: transparent;
    border: none;
    color: white;
}
  
.link:hover {
    text-decoration: underline; 
}

.create {
    background-color: #aee8ff;
    border-radius: 6px;
    padding: 10px;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: black;
}

/* responsive */

.nav-toggle {
    display: none;
}

.nav-open {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid white;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 800px) {
    .nav-toggle {
        display: block;
        cursor: pointer;
        margin: 20px 0 0 20px;
    }

    .nav-list-main {
        margin: 10px 0;
        align-self: center;
        justify-self: center;
        width: 100%;
    }

    .nav {
        display: none;
    }

    .nav-list {
        flex-direction: column;
        padding: 0;
        margin: 0 0 20px 0;
    }

    .nav-item {
        margin: 10px 0;
        align-self: center;
        justify-self: center;
        width: 100%;
    }

    
}