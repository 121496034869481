.body {
    background-color: white;
}

.feed-header {
    display: flex;
    justify-content: space-between;
    
    padding: 12px 24px;

    border-bottom: 1px solid grey;
}

.feed-header span {
    font-size: 24px;
}

.feed-filters {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 12px;
}

.scroll {
    padding: 15px;
    background-color: transparent;
    width: 95%;
    max-width: 95%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    font-size: 1rem;
    line-height: 1.6;
}

.feed-filter {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 6px 12px;
    margin: 0px 6px;

    border-radius: 4px;
    border: 1px solid grey;
    cursor: pointer;
}

.current-filters {
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid grey;
}

.current-filter {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 6px 12px;
    margin: 12px 6px 12px 18px;

    border-radius: 4px;
    border: 1px solid grey;
    cursor: pointer;
}

.feed {
    display: flex;
    flex-direction: column;
}

.feed-item {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;

    padding: 24px;
    border-bottom: 1px solid grey;
}

.feed-item:hover {
    background-color: rgb(243, 243, 243);
}

.feed-item-header {
    font-size: 28px;
}

.feed-item-desc {
    font-size: 18px;
    margin-top: 8px;
    text-align: left;
}

.feed-footer {
    margin-top: 12px;
    color: grey;
}

.feed-item-date {
}

.feed-footer-bullet {
    margin: 0px 6px;
}

.feed-item-tags {

}

/* loading spinner */
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
    margin-top: 100px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
