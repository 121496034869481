* {
    text-align: center;
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    height: 80vh;
}

.dash-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px 0 20px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #aee8ff;
}

.dash-nav button {
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: #aee8ff;
    cursor: pointer;
    padding: 0 20px;
}

.dash-nav button:hover {
    color: #00435d;
}

.dashboard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.create-feed {
    width: 100%;
    max-width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 10px;
}

.create-feed form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
}

.create-feed form input {
    margin: 10px 0 18px 0;
    width: 30%;
    border-radius: 4px;
    border: .5px solid #aee8ff;
    background-color: transparent;
    height: 25px;
    padding: 4px 0 4px 12px;

    color: #aee8ff;
    cursor: text;
}

.feedUrlLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.feedUrlLabel label {   
    margin-left: 10px;
}

.create-feed form input[type=submit]:hover {
    background-color: #aee8ff;
    color: black;
    cursor: pointer;
}

.edit-feed {
    width: 100%;
    max-width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    padding: 10px;
}

.edit-feed ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    text-align: left;
    padding-left: 0;
}

.edit-feed ul li span {
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: rgb(7, 160, 255);
    cursor: pointer;
}

.edit-feed ul li span button {
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: rgb(7, 160, 255);
    cursor: pointer;
    margin-left: 4px;
}

.edit-feed ul li span a {
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: rgb(7, 160, 255);
    cursor: pointer;
    text-decoration: none;
}

.feed-info {
    width: 100%;
    max-width: 80%;

    padding: 10px;
}

.feed-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feed-info input[type=text] {
    margin: 10px 0 0 0;
    width: 30%;
    border-radius: 4px;
    border: .5px solid #aee8ff;
    background-color: transparent;
    height: 25px;
    padding: 4px 0 4px 12px;

    color: #aee8ff;
    cursor: pointer;
}

.feed-info input[type=color] {
    margin: 10px 0 0 0;
}

.feed-cols {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.col-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 10px;
}

.col-input select {
    margin: 10px 0 18px 0;
    width: 90%;
    border-radius: 4px;
    border: .5px solid #aee8ff;
    background-color: transparent;
    height: 25px;
    padding: 4px 0 4px 12px;

    color: #aee8ff;
    cursor: pointer;

}

.filter-checklist {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}

.filter-checklist div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 16px 0 16px;
}

.filter-checklist div input[type=checkbox] {
    margin: 10px 0 18px 0;
    width: 90%;
    border-radius: 4px;
    border: .5px solid #aee8ff;
    background-color: transparent;
    height: 18px;
    padding: 4px 0 4px 12px;

    color: #aee8ff;
    cursor: pointer;
}

.password-protection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

}

.theme-select {
    margin: 10px 0 18px 0;
    width: 30%;
    border-radius: 4px;
    border: .5px solid #aee8ff;
    background-color: transparent;
    height: 25px;
    padding: 4px 0 4px 12px;

    color: #aee8ff;
    cursor: pointer;
}

.feed-submit {
    margin: 10px 0 18px 0;
    width: 30%;
    border-radius: 4px;
    border: .5px solid #aee8ff;
    background-color: transparent;
    height: 25px;
    padding: 4px 0 4px 12px;

    color: #aee8ff;
    cursor: pointer;

}

.feed-submit:hover {
    background-color: #aee8ff;
    color: black;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
    margin-top: 100px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}